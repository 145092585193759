import * as React from 'react'

function SvgPlayList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.455 8a6.545 6.545 0 1113.09 0 6.545 6.545 0 01-13.09 0zM8 0a8 8 0 100 16A8 8 0 008 0zm3 8L6 5v6l5-3z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgPlayList
