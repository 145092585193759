import { MatchPathError } from './MatchPathError'

interface PathwayCaseContext {
  caseId: string
  pathwayId: string
}

interface PathwayTrackContext {
  trackId: string
  pathwayId: string
}

export const usePathwayCaseId = (): PathwayCaseContext => {
  const { pathname } = window.location
  const matchPrivatePath = pathname.match(
    new RegExp('(/pathways/([^/]*))/?(cases)?/?((?:[^/])*)?'),
  )
  const matchPublicPath = pathname.match(
    new RegExp('(/share/([^/]*))(/cases/)?([^/]*)?'),
  )
  if (matchPublicPath) {
    const [, , pathwayId, , caseId] = matchPublicPath

    return {
      caseId,
      pathwayId,
    }
  }

  if (matchPrivatePath) {
    const [, , pathwayId, , caseId] = matchPrivatePath

    return {
      caseId,
      pathwayId,
    }
  }
  throw new MatchPathError('Pathway case id cannot be found!')
}

export const usePathwayTrackId = (): PathwayTrackContext => {
  const { pathname } = window.location
  const matchPrivatePath = pathname.match(
    new RegExp('(/pathways/([^/]*))/?(build)?/?((?:[^/])*)?'),
  )

  if (matchPrivatePath) {
    const [, , pathwayId, , trackId] = matchPrivatePath

    return {
      pathwayId,
      trackId,
    }
  }

  throw new MatchPathError('Pathway case id cannot be found!')
}
