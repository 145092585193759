import * as React from 'react'

function SvgEmailQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 26'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21.163 6.834l-6.734 6.734a4.006 4.006 0 01-5.658 0L2.037 6.834C2.026 6.96 2 7.074 2 7.2v9.6a4.005 4.005 0 004 4h11.2a4.005 4.005 0 004-4V7.2c0-.126-.026-.24-.037-.366z' />
      <path d='M13.298 12.437l7.307-7.308A3.989 3.989 0 0017.2 3.2H6a3.989 3.989 0 00-3.405 1.929l7.307 7.308a2.406 2.406 0 003.396 0z' />
    </svg>
  )
}

export default SvgEmailQuestion
