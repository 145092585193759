import * as React from 'react'

function SvgLogoSquare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width={22} height={22} rx={8} fill='currentColor' />
      <path
        d='M13.88 15.291a.202.202 0 00-.252-.027 5.085 5.085 0 01-7.634-3.16.205.205 0 00-.197-.16H4.203a.204.204 0 00-.2.241 7.055 7.055 0 0010.978 4.546.204.204 0 00.028-.311l-1.13-1.129zM11.945 5.798c0 .096.066.176.16.198a5.085 5.085 0 013.16 7.633c-.05.081-.04.184.027.251l1.129 1.13c.09.089.239.075.311-.029a7.054 7.054 0 00-4.546-10.978.203.203 0 00-.24.2v1.595h-.001zM5.996 9.787a5.092 5.092 0 013.792-3.792.205.205 0 00.16-.198V4.203a.203.203 0 00-.24-.2 7.057 7.057 0 00-5.705 5.705.204.204 0 00.2.24h1.595a.205.205 0 00.198-.16z'
        fill='#F8F9FB'
      />
    </svg>
  )
}

export default SvgLogoSquare
