import { type PrivatePathwayContext } from './types'
import { MatchPathError } from './MatchPathError'

export const usePrivatePathData = (): PrivatePathwayContext => {
  const { pathname } = window.location
  const matchPrivatePath = pathname.match(
    /(\/pathways\/([^/]*))\/?(build|cases|elements)?\/?((?:[^/])*)?/,
  )

  if (!matchPrivatePath) {
    throw new MatchPathError(
      'Private pathway data cannot be accessed! UsePrivatePathwayData hook was probably used in wrong place',
    )
  }

  const [, rootPath, pathwayId, mode, trackOrCaseId] = matchPrivatePath
  const isTrackList = mode === 'build' && !trackOrCaseId
  const isBuilding = mode === 'build' && !isTrackList
  const isPreviewing = mode === 'cases'
  const isPreviewingCase = mode === 'cases' && Boolean(trackOrCaseId)
  const isAForm = pathname.includes('/forms/')
  const trackId = isBuilding ? trackOrCaseId : undefined
  const pathwayCaseId = isPreviewing ? trackOrCaseId : ''

  return {
    pathwayId,
    trackId,
    pathwayCaseId,
    paths: {
      root: rootPath,
      buildRoot: `${rootPath}/build`,
      build: `${rootPath}/build/${trackId ?? ''}`,
      preview: `${rootPath}/cases/`,
      form: `${rootPath}/build/forms`,
      settings: `${rootPath}/settings`,
      elements: `${rootPath}/elements`,
      hosted_pages: `${rootPath}/hosted-pages`,
    },
    isTrackList,
    isBuilding,
    isPreviewing,
    isPreviewingCase,
    isAForm,
  }
}
