/* eslint-disable @typescript-eslint/naming-convention */
import { isNil } from 'lodash'

const toggleIntercom = (): void => {
  const { innerWidth } = window
  const hide_default_launcher = innerWidth < 1024
  // @ts-expect-error Intercom is loaded through cdn script, so typescript doesn't know about it
  Intercom('update', {
    hide_default_launcher,
  })
}

export const loadIntercomWidget = (app_id: string): void => {
  // @ts-expect-error Intercom is loaded through cdn script, so typescript doesn't know about it
  const { Intercom } = window
  if (!isNil(Intercom)) {
    Intercom('boot', {
      app_id,
    })

    toggleIntercom()
    window.addEventListener('resize', () => {
      toggleIntercom()
    })
  } else {
    console.warn(
      'Intercom is not loaded because it is not available in the global scope. This may be because you are running the application locally',
    )
  }
}
