// @ts-nocheck TODO: remove this comment and fix errors
import { Button } from '@awell/ui-kit/components/Button'
import { Logo, LogoSizes } from '@awell/ui-kit/components/Logo'
import {
  type RouteComponentProps,
  useLocation,
  useNavigate,
} from '@reach/router'
import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppHeader as BaseAppHeader,
  useHeaderStyles,
} from '@awell/ui-kit/components/AppHeader'

import { usePageTitleQuery } from './types'
import { useStyles } from './useStyles'
import { environment } from '@awell/libs-web/environment'

export const PublicHeader: FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const { appHeaderWithBorder } = useHeaderStyles()

  const {
    data: { pageTitle: pathwayCaseName },
  } = usePageTitleQuery()

  const { pathname } = useLocation()
  const pageTitle = (() => {
    switch (true) {
      case pathname.includes('register'):
        return t('create_account')
      case pathname.includes('share'):
        return `${pathwayCaseName}`
      default:
        return 'Awell'
    }
  })()

  const handleLogin = async () => {
    await navigate(`${environment.urls.careops}/login`, { replace: true })
  }
  return (
    <BaseAppHeader className={appHeaderWithBorder}>
      <div className={classes.logo}>
        <Logo size={LogoSizes.small} />
      </div>
      <h4 className={classes.pageTitle}>{pageTitle}</h4>
      <>
        <div className={classes.grow} />
        <Button variant='text' onClick={handleLogin}>
          {t('login')}
        </Button>
      </>
    </BaseAppHeader>
  )
}
