interface PathwayContext {
  pathwayId?: string
  trackId?: string
  pathwayCaseId?: string
  paths?: {
    root: string
    buildRoot: string
    build: string
    preview: string
    form: string
    settings: string
    elements: string
  }
  isTrackList?: boolean
  isBuilding?: boolean
  isPreviewing?: boolean
  isPreviewingCase?: boolean
  isAForm?: boolean
  isSharing?: boolean
}

/*  DO NOT USE IN NEW COMPONENTS, when fixing existing ts errors, swap this hook with either
   `usePathwayId`, `usePrivatePathData` or `usePublicPathData`
 *  This file is used a lot to get data that then are passed as variables to query hooks,
 *  having that in mind, the fact that all return object properties are optional is potentially dangerous,
 *  and may lead to possible issues. New hooks are handling one specific useCase each.
 *   */

export const usePathwayContext = (): PathwayContext => {
  const { pathname } = window.location
  const matchPrivatePath = pathname.match(
    new RegExp('(/pathways/([^/]*))/?(build|cases|elements)?/?((?:[^/])*)?'),
  )
  const matchPublicPath = pathname.match(
    new RegExp('(/share/([^/]*))(/cases/)?([^/]*)?'),
  )

  if (matchPublicPath) {
    const [, share, pathwayId, mode, pathwayCaseId] = matchPublicPath
    const isPreviewing = mode === '/cases/'
    const isPreviewingCase = mode === '/cases/' && Boolean(pathwayCaseId)
    const isSharing = share.includes('/share/')
    return {
      pathwayId,
      pathwayCaseId,
      isPreviewing,
      isPreviewingCase,
      isSharing,
    }
  }
  if (matchPrivatePath) {
    const [, rootPath, pathwayId, mode, trackOrCaseId] = matchPrivatePath
    const isTrackList = mode === 'build' && !trackOrCaseId
    const isBuilding = mode === 'build' && !isTrackList
    const isPreviewing = mode === 'cases'
    const isPreviewingCase = mode === 'cases' && Boolean(trackOrCaseId)
    const isAForm = pathname.includes('/forms/')
    const trackId = isBuilding ? trackOrCaseId : undefined
    const pathwayCaseId = isPreviewing ? trackOrCaseId : undefined

    return {
      pathwayId,
      trackId,
      pathwayCaseId,
      paths: {
        root: rootPath,
        buildRoot: `${rootPath}/build`,
        build: `${rootPath}/build/${trackId}`,
        preview: `${rootPath}/cases/`,
        form: `${rootPath}/build/forms`,
        settings: `${rootPath}/settings`,
        elements: `${rootPath}/elements`,
      },
      isTrackList,
      isBuilding,
      isPreviewing,
      isPreviewingCase,
      isAForm,
    }
  }
  return {}
}
