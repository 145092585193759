import * as React from 'react'

function SvgNumberQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10.7 12.94h2.21l.2-1.92h-2.17l-.24 1.92z' />
      <path d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zm-1.1 6.42c-.02.11-.07.22-.15.32-.11.13-.28.23-.5.28h-1.66l-.24 1.92h1.56c.2.07.35.18.46.31.09.13.14.27.14.41 0 .16-.05.31-.16.44-.1.13-.27.23-.5.28h-1.68l-.27 2.36c-.08.21-.19.36-.32.46-.14.1-.28.15-.41.15-.18 0-.33-.06-.47-.19-.14-.13-.22-.33-.24-.58l.27-2.19h-2.18l-.31 2.36c-.08.21-.19.36-.32.46a.67.67 0 01-.41.15.62.62 0 01-.46-.19c-.13-.13-.21-.33-.23-.58l.25-2.19H7.82c-.25-.04-.43-.13-.55-.28a.75.75 0 01.01-.96c.13-.13.33-.21.59-.23h1.39l.22-1.92H8.26c-.13-.02-.24-.05-.34-.1a.526.526 0 01-.2-.16.717.717 0 01-.18-.48.641.641 0 01.29-.55c.12-.09.28-.14.48-.16h1.35l.27-2.2c.05-.23.14-.41.28-.53a.71.71 0 01.45-.16c.19 0 .36.08.51.23.15.15.21.38.19.68l-.25 1.99h2.17l.29-2.23c.05-.23.14-.4.28-.51.15-.12.3-.17.45-.17.19 0 .36.08.5.23.15.16.21.38.18.66l-.23 2.03h1.54c.2.07.35.17.45.31.08.1.13.21.15.32v.21h.01z' />
    </svg>
  )
}

export default SvgNumberQuestion
