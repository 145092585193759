import './polyfills'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { environment } from '@awell/libs-web/environment'
import { loadIntercomWidget } from '@awell/libs-web/intercom'
import App from './App'
import { initializeCrashReporter } from './crash-reporter'

if (module.hot != null) {
  module.hot.accept()
}

initializeCrashReporter()
ReactDOM.render(<App />, document.getElementById('app'))

loadIntercomWidget(environment.intercom_app_id)
