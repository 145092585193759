import * as React from 'react'

function SvgActivityCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.187 5.616a.5.5 0 01.064.704l-3.667 4.4a.5.5 0 01-.738.034l-2.2-2.2a.5.5 0 11.708-.708L7.166 9.66l3.317-3.98a.5.5 0 01.704-.063z'
        fill='#5E6C84'
      />
      <path d='M14.5 8a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z' stroke='#5E6C84' />
    </svg>
  )
}

export default SvgActivityCheckmark
