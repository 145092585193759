import * as React from 'react'

function SvgDateQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 3.91h-.5V2.83c0-.46-.37-.83-.83-.83h-.35c-.45 0-.83.37-.83.83v1.09H8.46V2.83c0-.46-.37-.83-.83-.83h-.34c-.46 0-.83.37-.83.83v1.09H6a2 2 0 00-2 2v11.99a2 2 0 002 2h12a2 2 0 002-2V5.92c0-1.11-.9-2.01-2-2.01zm.54 13.54c0 .5-.4.9-.9.9H6.36c-.5 0-.9-.4-.9-.9V8.42h13.08v9.03z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.04 17.14h2.91c.3 0 .54-.24.54-.54v-2.91c0-.3-.24-.54-.54-.54h-2.91c-.3 0-.54.24-.54.54v2.91c0 .29.24.54.54.54z'
      />
    </svg>
  )
}

export default SvgDateQuestion
