import * as React from 'react'

function SvgSplitView(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 3.5h-2v18h2v-18zM7.4 5.955v13.09H2.6V5.956h4.8zm0-1.455H2.6c-.424 0-.831.153-1.131.426A1.39 1.39 0 001 5.955v13.09c0 .386.169.756.469 1.029.3.273.707.426 1.131.426h4.8c.424 0 .831-.153 1.131-.426.3-.273.469-.643.469-1.029V5.956a1.39 1.39 0 00-.469-1.029A1.685 1.685 0 007.4 4.5zM20.6 5.955v13.09h-4.2V5.956h4.2zm0-1.455h-4.2c-.371 0-.727.153-.99.426-.262.273-.41.643-.41 1.029v13.09c0 .386.148.756.41 1.029.263.273.619.426.99.426h4.2c.371 0 .727-.153.99-.426.262-.273.41-.643.41-1.029V5.956c0-.386-.148-.756-.41-1.029a1.374 1.374 0 00-.99-.426z'
        fill='#344563'
      />
    </svg>
  )
}

export default SvgSplitView
