import { MatchPathError } from './MatchPathError'

interface PathwayContext {
  pathwayId: string
}

export const usePathwayId = (): PathwayContext => {
  const { pathname } = window.location
  const matchPrivatePath = pathname.match(
    new RegExp('(/pathways/([^/]*))/?(build|cases|elements)?/?((?:[^/])*)?'),
  )
  const matchPublicPath = pathname.match(
    new RegExp('(/share/([^/]*))(/cases/)?([^/]*)?'),
  )

  if (matchPublicPath) {
    const [, , pathwayId] = matchPublicPath
    return {
      pathwayId,
    }
  }

  if (matchPrivatePath) {
    const [, , pathwayId] = matchPrivatePath
    return {
      pathwayId,
    }
  }

  throw new MatchPathError('Pathway id cannot be found!')
}
