import * as React from 'react'

function SvgRenameItem(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 3a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V4v-.01 7.239h-1.8V19.2H4.8V4.8h7.971V3H4z'
        fill='currentColor'
      />
      <path
        d='M9 12.7l8.286-8.286 2.3 2.3L11.3 15H9v-2.3z'
        stroke='#42526E'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgRenameItem
