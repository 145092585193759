import type { Member } from '@stytch/vanilla-js'
import { isNil } from 'lodash'

export const StytchRoles = {
  settings_admin: 'settings_admin',
  clinican_default: 'clinician_default',
  clinician_worklist: 'clinician_worklist',
  clinician_patient_dashboard: 'clinician_patient_dashboard',
  stytch_admin: 'stytch_admin',
  stytch_member: 'stytch_member',
  studio_viewer: 'studio_viewer',
}

export const memberIsAdmin = (
  member?: Pick<Member, 'trusted_metadata' | 'roles'> | null,
): boolean => {
  if (isNil(member)) {
    return false
  }
  return (
    ((member.trusted_metadata?.permissions ?? []) as Array<string>).includes(
      'manage-settings',
    ) ||
    member.roles.find(r => r.role_id === StytchRoles.settings_admin) !==
      undefined
  )
}
