import * as React from 'react'

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x={2} y={5} width={20} height={2} rx={1} fill='currentColor' />
      <rect x={2} y={11} width={20} height={2} rx={1} fill='currentColor' />
      <rect x={2} y={17} width={20} height={2} rx={1} fill='currentColor' />
    </svg>
  )
}

export default SvgMenu
