import * as React from 'react'

function SvgStepStart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 14'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.83 6.18a1 1 0 010 1.64l-7.257 5.079A1 1 0 010 12.079V1.921a1 1 0 011.573-.82l7.257 5.08z'
        fill='#253858'
      />
    </svg>
  )
}

export default SvgStepStart
