import * as React from 'react'

function SvgTransitionCondition(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 2.4c0-.22.18-.4.4-.4h3.108a.2.2 0 01.14.343L4.406 3.554l3.866 3.794a1 1 0 01.3.713V13.6a.4.4 0 01-.4.4h-.343a.4.4 0 01-.4-.4V8.519a1 1 0 00-.3-.715l-3.535-3.46L2.34 5.569A.2.2 0 012 5.425V2.4zM13.6 2c.22 0 .4.18.4.4v3.025a.2.2 0 01-.34.143l-1.254-1.223-2.578 2.532a.4.4 0 01-.56.001l-.232-.226a.4.4 0 01-.002-.572L11.6 3.56l-1.248-1.217a.2.2 0 01.14-.343H13.6z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTransitionCondition
