import { type RouteComponentProps } from '@reach/router'
import React, { type FC, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { useGetPathwayCaseTitleQuery } from './types'
import { usePathwayNavigationInfo } from '../../hooks/usePathwayNavigationInfo'

const PathwayCasePage = lazy(
  async () =>
    await import(/* webpackPrefetch: true */ '../../pages/PathwayCase').then(
      module => ({
        default: module.PathwayCasePage,
      }),
    ),
)

interface PathwayCaseRouteProps {
  pathwayId: string
  caseId: string
}
export const PathwayCaseRoute: FC<
  RouteComponentProps<PathwayCaseRouteProps>
> = ({ pathwayId, caseId }) => {
  const { t } = useTranslation()
  const { addBreadcrumbs, updateBreadcrumb } = useBreadcrumbs()

  const { data } = useGetPathwayCaseTitleQuery({
    variables: {
      id: caseId!,
    },
  })
  const { pathwayName } = usePathwayNavigationInfo(pathwayId)
  const pathwayCaseName = data?.pathway_case?.pathway_case?.title ?? ''

  const breadcrumbs = [
    {
      label: pathwayName ?? '',
      url: `/pathways/${pathwayId!}`,
      level: 1,
    },
    {
      label: t('case_plural'),
      url: `/pathways/${pathwayId!}/cases`,
      level: 2,
    },
    {
      label: pathwayCaseName,
      url: `/pathways/${pathwayId!}/cases/${caseId!}`,
      level: 3,
    },
  ]

  useEffect(() => {
    if (!isEmpty(pathwayName)) {
      // return statement needed to not break breadcrumbs when navigating between pages
      return addBreadcrumbs(breadcrumbs)
    }
  }, [pathwayName])

  useEffect(() => {
    if (pathwayCaseName) {
      updateBreadcrumb(breadcrumbs[2])
    }
  }, [pathwayCaseName])

  return <PathwayCasePage />
}
