import { useUserQuery, type User } from './types'

interface UserHook {
  user?: User
  loading: boolean
}

export const useUser = (): UserHook => {
  const { data, loading } = useUserQuery()
  const user = data?.user.user
  return { user, loading }
}
