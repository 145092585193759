import { type RouteComponentProps } from '@reach/router'
import React, { type FC, lazy, useEffect } from 'react'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { ErrorBoundary } from '../../components/ErrorBoundary'

const PathwayList = lazy(
  async () =>
    await import(
      /* webpackPrefetch: true */ '../../components/PathwayList'
    ).then(module => ({
      default: module.PathwayListContainer,
    })),
)

export const Pathways: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()

  const breadcrumb = {
    label: 'Care flows',
    url: '/pathways',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  return (
    <ErrorBoundary>
      <PathwayList />
    </ErrorBoundary>
  )
}

Pathways.displayName = 'Pathways'
