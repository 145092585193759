import * as React from 'react'

function SvgVariableDatapoint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 44 60'
      fill='none'
      {...props}
    >
      <path
        d='M36.93 2.737C32.903 1.56 27.6.913 22 .913s-10.902.648-14.93 1.824C4.127 3.596 0 5.277 0 8.335v35.156c0 3.06 4.13 4.74 7.07 5.598 4.028 1.176 9.33 1.824 14.93 1.824s10.902-.648 14.93-1.824c2.942-.858 7.07-2.54 7.07-5.598V8.335c0-3.057-4.125-4.739-7.07-5.598zM22 3.843c5.328 0 10.339.606 14.108 1.706 4.048 1.181 4.959 2.439 4.959 2.786 0 .343-.91 1.582-4.95 2.735-3.751 1.07-8.765 1.66-14.117 1.66-12.31 0-19.067-3.005-19.067-4.395 0-1.353 6.62-4.492 19.067-4.492zm14.108 42.434c-3.77 1.1-8.78 1.706-14.108 1.706-12.379 0-19.067-3.122-19.067-4.492v-1.955c3.352 1.91 9.085 2.94 14.576 3.282l.182-2.924c-4.373-.272-8.326-.963-11.132-1.946-2.96-1.036-3.626-2.045-3.626-2.316v-1.946c4.771 2.692 13.552 3.41 19.067 3.41 5.592 0 14.33-.737 19.067-3.41v7.805c0 .347-.911 1.605-4.96 2.786zM22 36.167c-12.31 0-19.067-3.004-19.067-4.395v-1.955c3.352 1.91 9.085 2.94 14.576 3.283l.182-2.924c-10.175-.634-14.758-3.212-14.758-4.263v-1.945C7.706 26.66 16.49 27.378 22 27.378c5.498 0 14.295-.718 19.067-3.41v7.805c0 1.33-6.542 4.394-19.067 4.394zm0-11.719c-12.31 0-19.067-3.004-19.067-4.394v-1.955c3.352 1.909 9.085 2.94 14.576 3.282l.182-2.924c-10.175-.634-14.758-3.212-14.758-4.263V12.25C7.706 14.94 16.49 15.659 22 15.659c5.617 0 10.916-.63 14.922-1.773 1.68-.479 3.06-1.025 4.145-1.637v7.805c0 1.33-6.542 4.394-19.067 4.394z'
        fill='#DFE1E6'
      />
      <path
        d='M21 18.913h2v3h-2v-3zM21 29.913h2v3h-2v-3zM21 41.913h2v3h-2v-3z'
        fill='#3476E0'
      />
    </svg>
  )
}

export default SvgVariableDatapoint
