import { type RouteComponentProps } from '@reach/router'
import React, { type FC } from 'react'
import { PublicHeader } from './PublicHeader'
import { PrivateHeader } from './PrivateHeader'
import { useStytchMember } from '@stytch/react/b2b'
import { isNil } from 'ramda'

export const AppHeader: FC<RouteComponentProps> = () => {
  const { member } = useStytchMember()
  const isAuthenticated = !isNil(member)

  return <>{isAuthenticated ? <PrivateHeader /> : <PublicHeader />}</>
}

AppHeader.displayName = 'AppHeader'
