import * as React from 'react'

function SvgSignatureQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zm-.58 13.35H6.89c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h10.53c.28 0 .5.22.5.5 0 .27-.22.5-.5.5zm.42-5.05l-.2.36c-.19.31-.39.67-.89.8-.44.12-.92.05-1.31-.19-.41-.23-.66-.62-.86-.96-.06.1-.12.2-.19.29-.22.3-.56.77-1.11.89-.72.16-1.13-.41-1.37-.73l-.06-.09s-.12-.17-.24-.39c-.05.05-.1.11-.14.18-.21.25-.47.57-.88.76-.55.26-1.16.19-1.68-.08-.34.34-.74.59-1.25.62H7.6c-.29 0-.56-.1-.77-.3-.17-.17-.28-.39-.34-.69l-.08-.44a.59.59 0 01-.02-.14c0-.25.2-.45.45-.45s.45.2.45.45l.08.42c.02.12.06.17.07.19.05.03.1.06.17.06.21-.01.41-.12.59-.27-.14-.16-.25-.32-.35-.49a4.092 4.092 0 01-.02-3.86c.21-.38.44-.66.72-.85.39-.27.88-.33 1.28-.16.36.15.65.48.78.91.1.35.09.71.07 1-.09.95-.39 1.91-.88 2.76-.08.15-.2.34-.31.53.24.09.5.1.74-.01.21-.09.36-.26.56-.52.21-.26.33-.49.38-.74.05-.25.28-.4.53-.35.17.03.31.17.35.35.07.17.44.87.53 1l.03.06c.21.28.33.42.46.39.21-.05.39-.28.58-.55.2-.28.35-.6.44-.93.05-.17.2-.31.38-.33.19-.02.36.08.44.24l.24.49c.22.44.42.79.71.96.19.12.43.15.64.09.14-.03.19-.1.36-.39l.21-.37c.07-.14.22-.24.4-.24.25 0 .45.2.45.45 0 .11-.03.2-.08.27z'
        fill='#000'
      />
      <path
        d='M9.76 8.39a.579.579 0 00-.27-.35.444.444 0 00-.13-.02c-.1 0-.22.03-.31.1-.15.11-.29.28-.43.54-.49.92-.48 2.1.02 3.02.03.07.08.14.14.21.1-.16.19-.31.26-.43.42-.74.69-1.57.76-2.4.02-.21.03-.46-.04-.67z'
        fill='#000'
      />
    </svg>
  )
}

export default SvgSignatureQuestion
