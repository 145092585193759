// @ts-nocheck TODO: remove this comment and fix errors
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../useNotifications'
import { Track, useSetTrackTitleMutation } from './types'

interface RenameTrackHook {
  setTrackTitle: (title: string) => Promise<Track>
}

export const useSetTrackTitle = (trackId: string): RenameTrackHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const [setTrackTitleMutation] = useSetTrackTitleMutation()

  const setTrackTitle = async (title: string) => {
    try {
      const { data } = await setTrackTitleMutation({
        variables: {
          input: {
            track_id: trackId,
            title,
          },
        },
      })
      return data.setTrackTitle.track
    } catch (error) {
      notifyError({
        message: t('pathway_rename_track_error'),
        error,
      })
    }
    return undefined
  }
  return {
    setTrackTitle,
  }
}
