import * as React from 'react'

function SvgVariableArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 56 45'
      fill='none'
      {...props}
    >
      <path
        d='M27.293 32.62a1 1 0 001.414 0l6.364-6.364a1 1 0 00-1.414-1.414L28 30.499l-5.657-5.657a1 1 0 00-1.414 1.414l6.364 6.364zM27-8.087v40h2v-40h-2z'
        fill='#DFE1E6'
      />
    </svg>
  )
}

export default SvgVariableArrowDown
