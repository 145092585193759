import * as React from 'react'

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.795 17.027a1 1 0 011.498-1.32L10.586 18V4.414l.006-.116a1 1 0 011.994.116V18l2.293-2.293.094-.083a1 1 0 011.32 1.497l-2.586 2.586-.144.135a3 3 0 01-4.099-.135L6.88 17.121l-.084-.094z'
        fill='#1F1F1F'
      />
    </svg>
  )
}

export default SvgArrowDown
