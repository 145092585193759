import * as React from 'react'

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.613 7.21a1 1 0 00-1.32 1.497L17.586 11H4l-.117.007A1 1 0 004 13h13.586l-2.293 2.293-.083.094a1 1 0 001.497 1.32l2.586-2.586.135-.144a3 3 0 00-.135-4.098l-2.586-2.586-.094-.083z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgArrowRight
