import { useTranslation } from 'react-i18next'
import { createApolloCacheUpdate, updateQuery } from '@awell/libs-web/graphql'
import { useNotifications } from '../useNotifications'
import {
  type AddTrackToPathwayMutation,
  GetTracksDocument,
  type GetTracksQuery,
  type GetTracksQueryVariables,
  type Track,
  useAddTrackToPathwayMutation,
} from './types'
import { useRefetchQueriesForDataPoints } from '../useDataPointRefetchQueries'

interface UseAddTrackToPathwayHook {
  addTrackToPathway: (title: string) => Promise<Track | undefined>
}

export const useAddTrackToPathway = (
  pathwayId: string,
): UseAddTrackToPathwayHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const refetchQueries = useRefetchQueriesForDataPoints()
  const [addTrackToPathwayMutation] = useAddTrackToPathwayMutation()

  const addTrackToPathway = async (title: string) => {
    const variables: GetTracksQueryVariables = {
      pathwayId,
    }
    try {
      const update = createApolloCacheUpdate<
        AddTrackToPathwayMutation,
        GetTracksQuery,
        GetTracksQueryVariables
      >({
        query: GetTracksDocument,
        variables,
        updateFunction: ({ query, result }) => {
          const track = result.data?.addTrackToPathway.track
          if (track) {
            const tracks = [...(query.pathway.pathway.tracks ?? []), track]
            return updateQuery<GetTracksQuery, Array<Track>>(
              query,
              ['pathway', 'pathway', 'tracks'],
              tracks,
            )
          }
          return query
        },
      })
      const { data } = await addTrackToPathwayMutation({
        variables: {
          input: {
            pathway_id: pathwayId,
            title,
          },
        },
        refetchQueries,
        update,
      })
      return data?.addTrackToPathway.track
    } catch (error) {
      notifyError({
        message: t('pathway_add_track_error'),
        error,
      })
      return undefined
    }
  }
  return {
    addTrackToPathway,
  }
}
