import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colors.neutralLight20,
    borderRadius: spacing.xxs,
    padding: spacing.xxxs,
  },
  indicator: {
    display: 'none',
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    backgroundColor: colors.neutralLight20,
    borderRadius: spacing.xxs,
    minWidth: 'auto',
    minHeight: 'auto',
    padding: `${spacing.xxxs} ${spacing.s}`,
    lineHeight: '1.429rem', // 20px
    '&:hover': {
      color: colors.brand100,
    },
  },
  tabSelected: {
    color: colors.neutralLight0,
    backgroundColor: colors.brand100,
    '&:hover': {
      color: colors.neutralLight0,
    },
  },
})
