import * as React from 'react'

function SvgBookmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 2a1 1 0 011-1h14a1 1 0 011 1v16a1 1 0 11-2 0V3H6a1 1 0 01-1-1zm11.534 3H3.466A.477.477 0 003 5.484v17.032c0 .266.21.484.466.484.08 0 .154-.02.22-.058L10 19.42l6.314 3.522a.455.455 0 00.63-.198.495.495 0 00.056-.228V5.484A.474.474 0 0016.534 5zM11 9a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V9z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgBookmark
