import * as React from 'react'

function SvgTransitionDelay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 8A6 6 0 112 8a6 6 0 0112 0zm-6 7A7 7 0 108 1a7 7 0 000 14zM8 3a.5.5 0 00-.5.5v5a.5.5 0 001 0v-5A.5.5 0 008 3z'
        fill='#42526E'
      />
      <rect
        x={11.555}
        y={8}
        width={1}
        height={4.017}
        rx={0.5}
        transform='rotate(90 11.555 8)'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTransitionDelay
