import * as React from 'react'

function SvgSliderQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zm.5 8.5h-1.53a2.5 2.5 0 01-4.9 0H5.5c-.27 0-.5-.22-.5-.5 0-.27.23-.5.5-.5h6.57a2.5 2.5 0 014.9 0h1.53c.28 0 .5.23.5.5 0 .28-.22.5-.5.5z' />
      <path d='M14.5 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z' />
    </svg>
  )
}

export default SvgSliderQuestion
