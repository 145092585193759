import * as React from 'react'

function SvgVariableLabel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 62 32'
      fill='none'
      {...props}
    >
      <path
        d='M60.56 14.681L49.618 1.453A3.987 3.987 0 0046.533 0H6.985A6.01 6.01 0 00.98 6.004v19.905a6.01 6.01 0 006.004 6.004h39.548c1.198 0 2.323-.53 3.084-1.452L60.56 17.23a2.002 2.002 0 000-2.55zM6.985 27.911a2.004 2.004 0 01-2.001-2.002V6.004c0-1.103.898-2.001 2-2.001h39.55l9.886 11.953-9.887 11.954H6.985z'
        fill='#DFE1E6'
      />
      <path
        d='M45.009 9.952a6.01 6.01 0 00-6.004 6.004 6.01 6.01 0 006.004 6.004 6.01 6.01 0 006.004-6.004 6.01 6.01 0 00-6.004-6.004zm0 8.005a2.004 2.004 0 01-2.001-2.001c0-1.103.898-2.001 2-2.001 1.104 0 2.002.898 2.002 2a2.004 2.004 0 01-2.001 2.002z'
        fill='#DFE1E6'
      />
      <rect x={9.981} y={10} width={22} height={3} rx={1} fill='#3476E0' />
      <rect x={9.981} y={16} width={16} height={3} rx={1} fill='#3476E0' />
    </svg>
  )
}

export default SvgVariableLabel
