import * as React from 'react'

function SvgHelp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 20a9 9 0 100-18 9 9 0 000 18zm-.86-2.443a1.208 1.208 0 101.343-2.008 1.208 1.208 0 00-1.342 2.008zm1.577-4.144c0-.552.135-1.154 1.114-1.811 1.205-.81 2.57-1.727 2.57-3.77 0-1.397-.804-2.55-2.265-3.24-.489-.231-1.21-.36-1.976-.352-1.083.013-1.998.314-2.717.893C8.056 6.248 7.954 7.57 7.948 7.717a.846.846 0 101.69.081c0-.01.068-.71.866-1.352.423-.34.973-.51 1.68-.518.564-.007 1.022.088 1.228.186.875.416 1.298.978 1.298 1.719 0 1.088-.658 1.583-1.822 2.364-1.253.842-1.861 1.893-1.861 3.216a.845.845 0 101.69 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgHelp
