// @ts-nocheck TODO: remove this comment and fix errors
import { PopoverSelectorItemType } from '@awell/ui-kit/components/PopoverSelector'
import React, { FC } from 'react'
import { Track as TrackIcon } from '@awell/ui-kit/components/Icons'
import { NavigationWithDropdown } from '@awell/ui-kit/components/NavigationWithDropdown'
import { usePathwayTracks } from '../../../hooks/usePathwayTracks'

interface PathwayTrackNavigationProps {
  navigationLink: string
  pathwayName: string
  onTrackSelect: (item: PopoverSelectorItemType) => void
  pathwayId: string
  trackId: string
}

export const PathwayTrackNavigation: FC<PathwayTrackNavigationProps> = ({
  navigationLink,
  pathwayName,
  onTrackSelect,
  pathwayId,
  trackId,
}) => {
  const { tracks, loading } = usePathwayTracks(pathwayId)
  const activeTrack = tracks.find(_track => _track.id === trackId)

  return (
    <>
      <NavigationWithDropdown
        navigationLink={navigationLink}
        parentPageName={pathwayName}
        onSelect={onTrackSelect}
        dropdownItems={tracks}
        activeItem={activeTrack}
        icon={TrackIcon}
        loading={loading}
      />
    </>
  )
}
