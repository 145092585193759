import * as React from 'react'

function SvgTransitionGoto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2.5a.5.5 0 00-1 0v10a.5.5 0 001 0v-10zM11.25 7V4.469L15 7.5l-3.75 3.031V8H6.5a.5.5 0 010-1h4.75z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTransitionGoto
