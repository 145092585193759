import * as React from 'react'

function SvgVariableFallback(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 65'
      fill='none'
      {...props}
    >
      <path
        d='M31 10.413c-13.2 0-24 10.8-24 24s10.8 24 24 24 24-10.8 24-24-10.8-24-24-24zm0 43.2c-10.56 0-19.2-8.64-19.2-19.2 0-10.56 8.64-19.2 19.2-19.2 10.56 0 19.2 8.64 19.2 19.2 0 10.56-8.64 19.2-19.2 19.2z'
        fill='#DFE1E6'
      />
      <path
        d='M31 22.413c-4.08 0-7.2 3.12-7.2 7.2h4.8c0-1.44.96-2.4 2.4-2.4s2.4.96 2.4 2.4c0 .96-.72 1.92-1.68 2.16-1.92.72-3.12 2.64-3.12 4.8v2.64h4.8l-.24-2.88c3.12-.96 5.04-3.6 5.04-6.72 0-4.08-3.12-7.2-7.2-7.2zM28.6 41.614h4.8v4.8h-4.8v-4.8z'
        fill='#3476E0'
      />
    </svg>
  )
}

export default SvgVariableFallback
