import React, { FC, Suspense, lazy } from 'react'
import { RouteComponentProps } from '@reach/router'
import { FullHeightRouter } from '@awell/libs-web/routing'

const PublicRoutePreview = lazy(() =>
  import(/* webpackPrefetch: true */ './PublicRoutePreview').then(module => ({
    default: module.PublicRoutePreview,
  })),
)
export const PublicRoute: FC<RouteComponentProps> = () => {
  return (
    <Suspense fallback=''>
      <FullHeightRouter>
        <PublicRoutePreview path=':pathwayId/cases/*' />
      </FullHeightRouter>
    </Suspense>
  )
}

PublicRoute.displayName = 'PublicRoute'
