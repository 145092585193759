import {
  previewLayoutVar,
  layoutVar,
  currentPageTitleVar,
  trackCanvasSettingsVar,
  builderUrlVar,
  previewUrlVar,
  calculationsLibraryVar,
} from './index'

export const typePolicies = {
  Query: {
    fields: {
      simulatorLayout: {
        read() {
          return previewLayoutVar()
        },
      },
      layout: {
        read() {
          return layoutVar()
        },
      },
      trackCanvasSettings: {
        read() {
          return trackCanvasSettingsVar()
        },
      },
      sessionUrls: {
        read() {
          return {
            builder: builderUrlVar(),
            preview: previewUrlVar(),
          }
        },
      },
      pageTitle: {
        read() {
          return currentPageTitleVar()
        },
      },
      calculationsLibrary: {
        read() {
          return calculationsLibraryVar()
        },
      },
    },
  },
  // IMPORTANT
  // Given that some activity objects share the same id (example: 'Checklist' object shares id with underlying 'Action; object),
  // we have to tell GraphQL cache that it has to take id & type into account when identifying this object.
  ActivityObject: {
    keyFields: ['id', 'type'],
  },
  ActivitySubject: {
    keyFields: ['id', 'type'],
  },
}
