import * as React from 'react'

function SvgWebhooks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 64 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M32.744 0C24.541 0 17.86 6.68 17.86 14.884c0 4.895 2.43 9.192 6.093 11.907l-7.348 12.14c-.058-.018-.122-.03-.186-.047-1.535-.413-3.134-.186-4.512.604-2.843 1.646-3.826 5.297-2.186 8.14a5.953 5.953 0 005.163 2.977c1.011 0 2.04-.25 2.976-.791a5.975 5.975 0 002.791-3.628 5.893 5.893 0 00-.604-4.512c-.25-.43-.594-.813-.93-1.162l8.092-13.303.791-1.302-1.302-.744a11.924 11.924 0 01-5.86-10.28A11.885 11.885 0 0132.743 2.978a11.885 11.885 0 0111.907 11.907c0 1.226-.168 2.378-.512 3.488l2.838.884c.43-1.39.65-2.849.65-4.372C47.628 6.68 40.948 0 32.745 0zm0 8.93a5.957 5.957 0 00-5.953 5.954 5.958 5.958 0 005.953 5.953c.494 0 .983-.07 1.442-.186l7.163 12.977.698 1.302 1.348-.697a11.74 11.74 0 015.721-1.489 11.885 11.885 0 0111.907 11.907 11.885 11.885 0 01-11.907 11.907 11.917 11.917 0 01-8.279-3.349l-2.046 2.14c2.674 2.575 6.325 4.186 10.325 4.186C57.32 59.535 64 52.855 64 44.65c0-8.203-6.68-14.884-14.884-14.884-2.064 0-3.977.541-5.767 1.303L36.79 19.21c1.163-1.088 1.907-2.611 1.907-4.326a5.957 5.957 0 00-5.954-5.954zM11.256 30.186C4.773 31.802 0 37.692 0 44.651c0 8.204 6.68 14.884 14.884 14.884 7.674 0 13.82-5.924 14.604-13.395h13.907c.663 2.563 2.954 4.465 5.721 4.465a5.958 5.958 0 005.954-5.954 5.958 5.958 0 00-5.954-5.953c-2.767 0-5.058 1.9-5.72 4.465H26.79v1.488a11.885 11.885 0 01-11.907 11.907A11.885 11.885 0 012.977 44.651c0-5.593 3.849-10.244 9.023-11.535l-.744-2.93z'
        fill='#172B4D'
      />
    </svg>
  )
}

export default SvgWebhooks
