/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react'
import { environment } from '@awell/libs-web/environment'
import { type ErrorContext, type ErrorReporter } from './types'

export class SentryErrorReporter implements ErrorReporter {
  constructor() {
    Sentry.init({
      environment: environment.environment,
      dsn: environment.logging.crash_reporter.pathway_studio,
      release: environment.releases.pathway_studio,
      ignoreErrors: [],
    })
  }

  public setContext(context: ErrorContext): void {
    Sentry.configureScope(scope => {
      scope.setUser(context.user)
      scope.setTag('tenant', context.user.tenant?.name ?? 'Unknown tenant')
      scope.setTag('user_email', context.user.email ?? 'No email')
    })
  }

  // See https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
  // Unfortunately the capture context type isn't exported by sentry sdk so
  // instead we use a generic object
  public report(error: Error, captureContext?: Record<string, any>): void {
    // Skip crash reports in local to:
    // - reduce noise in sentry
    // - avoid impacting sentry quotas
    if (environment.environment !== 'local') {
      Sentry.captureException(error, captureContext)
    } else {
      console.error(error, captureContext)
    }
  }
}
