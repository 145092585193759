import * as React from 'react'

function SvgAttach(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.626 11.27l-6.915 6.915a4.517 4.517 0 01-6.388-6.388l6.915-6.915a3.012 3.012 0 014.259 4.259l-6.923 6.915a1.506 1.506 0 01-2.129-2.13l6.388-6.38'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgAttach
