/* eslint-disable no-console */
import { ApolloLink, ApolloProvider } from '@apollo/client'
import { type ErrorLink } from '@apollo/client/link/error'
import { environment } from '@awell/libs-web/environment'
import { createClient } from '@awell/libs-web/graphql'
import { flattenObject } from '@awell/ui-kit/utils'
import { pick } from 'lodash'
import React, { type FC } from 'react'
import { CrashReporter } from './crash-reporter'
import fragmentTypes from './generated/fragment-types'
import { typePolicies } from './local-state/typePolicies'

// Heap SDK is loaded in global scope through CDN (see index.html)
// If we start using this more extensively it would be best to load it as
// a dependency so that we can get types for it as well, but for now this will do
// @ts-expect-error see above
const { heap } = window

const featureTrackerLink = new ApolloLink((operation, forward) => {
  if (heap !== undefined) {
    try {
      const input = operation.variables.input as Record<string, any>
      heap.track(`feature_tracker_${operation.operationName}`, {
        api_call: operation.operationName,
        ...flattenObject(input),
      })
    } catch (error) {
      // Nothing to do here, if heap SDK is not correctly loaded or if the tracker
      // cannot submit data we shouldn't block the users from using it.
    }
  }
  return forward(operation)
})

const onError: ErrorLink.ErrorHandler = ({ operation, networkError }) => {
  // We don't want to report local network errors to Sentry, these are often caused by restarting the backend
  if (
    networkError &&
    environment.environment === 'local' &&
    networkError.message === 'Failed to fetch'
  ) {
    return
  }
  if (networkError) {
    CrashReporter.report(networkError, {
      contexts: {
        graphql: {
          operation: pick(operation, ['operationName', 'variables']),
          variables: JSON.stringify(operation.variables),
        },
      },
    })
  }
}

export const GraphqlWrapper: FC = ({ children }) => {
  const client = createClient({
    httpUri: environment.urls.design_api,
    wsUri: environment.urls.design_api_ws,
    onNetworkError: onError,
    extraLinks: [featureTrackerLink],
    cacheConfig: {
      possibleTypes: fragmentTypes.possibleTypes,
      typePolicies,
    },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
