// @ts-nocheck TODO: remove this comment and fix errors
import { PopoverSelectorItemType } from '@awell/ui-kit/components/PopoverSelector'
import React, { FC } from 'react'
import { PlayList as CaseIcon } from '@awell/ui-kit/components/Icons'
import { NavigationWithDropdown } from '@awell/ui-kit/components/NavigationWithDropdown'
import { usePathwayCases } from '../../../hooks/usePathwayCases'

interface PathwayCaseNavigationProps {
  navigationLink: string
  pathwayName: string
  onCaseSelect: (item: PopoverSelectorItemType) => void
  pathwayId: string
  pathwayCaseId: string
}

export const PathwayCaseNavigation: FC<PathwayCaseNavigationProps> = ({
  navigationLink,
  pathwayName,
  onCaseSelect,
  pathwayId,
  pathwayCaseId,
}) => {
  const { pathwayCases, loading } = usePathwayCases({ pathwayId })
  const activeCase = pathwayCases.find(_case => _case.id === pathwayCaseId)

  return (
    <>
      <NavigationWithDropdown
        navigationLink={navigationLink}
        parentPageName={pathwayName}
        onSelect={onCaseSelect}
        dropdownItems={pathwayCases}
        activeItem={activeCase}
        icon={CaseIcon}
        loading={loading}
      />
    </>
  )
}
