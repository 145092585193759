import * as React from 'react'

function SvgShortTextQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zm-5.96 9h-5c-.55 0-1-.45-1-1s.45-1 1-1h5c.55 0 1 .45 1 1s-.45 1-1 1zm5.37 0h-1.98c-.55 0-1-.45-1-1s.45-1 1-1h1.98c.55 0 1 .45 1 1s-.45 1-1 1z' />
    </svg>
  )
}

export default SvgShortTextQuestion
