import * as React from 'react'

function SvgLongTextQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zM7.04 6.81h8.9c.55 0 1 .45 1 1s-.45 1-1 1h-8.9c-.55 0-1-.45-1-1s.44-1 1-1zm0 4.19h5c.55 0 1 .45 1 1s-.45 1-1 1h-5c-.55 0-1-.45-1-1s.44-1 1-1zm7.41 6.19H7.04c-.55 0-1-.45-1-1s.45-1 1-1h7.41c.55 0 1 .45 1 1s-.45 1-1 1zM17.41 13h-1.98c-.55 0-1-.45-1-1s.45-1 1-1h1.98c.55 0 1 .45 1 1s-.45 1-1 1z' />
    </svg>
  )
}

export default SvgLongTextQuestion
