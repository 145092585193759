import * as React from 'react'

function SvgStepDocumentation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 12'
      fill='none'
      {...props}
    >
      <rect x={0.5} y={0.5} width={15} height={11} rx={1.5} stroke='#5E6C84' />
      <path stroke='#5E6C84' strokeLinecap='round' d='M3.5 3.5h9M3.5 6.5h6' />
    </svg>
  )
}

export default SvgStepDocumentation
