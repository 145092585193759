import * as React from 'react'

function SvgApi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 6.4a2.4 2.4 0 114.565 1.035L10 8.868l.303-.302a2.4 2.4 0 013.394 0l.303.302 1.433-1.433a2.4 2.4 0 111.131 1.13L15.132 10l.303.303a2.4 2.4 0 010 3.394l-.303.303 1.433 1.434a2.4 2.4 0 11-1.13 1.13L14 15.132l-.303.303a2.4 2.4 0 01-3.394 0L10 15.132l-1.434 1.433a2.4 2.4 0 11-1.13-1.13L8.868 14l-.303-.303a2.4 2.4 0 010-3.394L8.868 10 7.435 8.567A2.4 2.4 0 014 6.4zm2.4-.8a.8.8 0 100 1.6.8.8 0 000-1.6zm0 11.2a.8.8 0 100 1.6.8.8 0 000-1.6zm10.4.8a.8.8 0 111.6 0 .8.8 0 01-1.6 0zm.8-12a.8.8 0 100 1.6.8.8 0 000-1.6zm-6.165 4.097a.8.8 0 011.13 0l1.739 1.738a.8.8 0 010 1.13l-1.739 1.74a.8.8 0 01-1.13 0l-1.739-1.74a.8.8 0 010-1.13l1.739-1.738z'
        fill='#42526E'
      />
    </svg>
  )
}

export default SvgApi
