import * as React from 'react'

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.795 6.973a1 1 0 001.498 1.32L10.586 6v13.586l.006.116a1 1 0 001.994-.116V6l2.293 2.293.094.083a1 1 0 001.32-1.497l-2.586-2.586-.144-.135a3 3 0 00-4.099.135L6.88 6.879l-.084.094z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgArrowUp
