import * as React from 'react'

function SvgRssFeed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 11a8 8 0 018 8M5 5c7.732 0 14 6.268 14 14M7 18a1 1 0 11-2 0 1 1 0 012 0z'
        stroke='#42526E'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgRssFeed
