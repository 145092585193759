import * as React from 'react'

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 2a1 1 0 00-1 1v2.29c-.466.138-.91.324-1.33.551l-1.62-1.62a1 1 0 00-1.414 0L4.222 5.637a1 1 0 000 1.414l1.62 1.62c-.228.42-.414.864-.552 1.33H3a1 1 0 00-1 1v2a1 1 0 001 1h2.29c.138.466.324.91.551 1.33l-1.62 1.62a1 1 0 000 1.414l1.415 1.414a1 1 0 001.414 0l1.62-1.62c.42.228.864.414 1.33.552V21a1 1 0 001 1h2a1 1 0 001-1v-2.29c.466-.138.91-.324 1.33-.551l1.62 1.62a1 1 0 001.414 0l1.414-1.415a1 1 0 000-1.414l-1.62-1.62c.228-.42.414-.864.552-1.33H21a1 1 0 001-1v-2a1 1 0 00-1-1h-2.29a6.951 6.951 0 00-.551-1.33l1.62-1.62a1 1 0 000-1.414l-1.415-1.414a1 1 0 00-1.414 0l-1.62 1.62A6.957 6.957 0 0014 5.29V3a1 1 0 00-1-1h-2zm1 14a4 4 0 100-8 4 4 0 000 8z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgSettings
