import * as React from 'react'

function SvgDataCatalog(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.201 3.667c.555 0 1.099.374 1.211.832l.2 1.251H3.388l.2-1.251c.112-.458.656-.832 1.212-.832H19.2zM2.58 6.792h18.84a.56.56 0 01.43.198.61.61 0 01.147.463l-.923 12.155a.814.814 0 01-.25.517.754.754 0 01-.517.208H3.693a.754.754 0 01-.518-.208.814.814 0 01-.249-.517L2.003 7.453a.628.628 0 01.148-.463.582.582 0 01.429-.198zm2.414 6.084c0-.484.392-.876.875-.876h3.504a.876.876 0 010 1.752H5.869a.876.876 0 01-.875-.876zm0 3.503c0-.484.392-.876.875-.876h7.007a.876.876 0 110 1.752H5.869a.876.876 0 01-.875-.876z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDataCatalog
