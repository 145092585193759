import { Tab, Tabs } from '@material-ui/core'
import { isEmpty } from 'lodash/fp'
import { useLocation, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FC } from 'react'
import { usePathwayContext } from '../../../hooks/usePathwayContext'
import { useStyles } from './useStyles'

interface FormBuilderNavigationProps {
  backUrl?: string
}

export const FormBuilderNavigation: FC<FormBuilderNavigationProps> = ({
  backUrl = '',
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { paths } = usePathwayContext()
  const classes = useStyles()

  const getFormIdFromPath = () => {
    const formIdMatch = pathname.match(
      new RegExp('(?:.*/build/forms/)?([^/]*)?.*'),
    )
    if (formIdMatch) {
      return formIdMatch[1]
    }
    return ''
  }

  const getFormPathForTab = (tab: string) => {
    const formId = getFormIdFromPath()
    const path = `${paths?.form}/${formId}`
    return isEmpty(tab) ? path : path.concat(`/${tab}`)
  }

  const handleChange = async (_: ChangeEvent, path: string) => {
    const url = backUrl ? path.concat(`?back_url=${backUrl}`) : path
    await navigate(url)
  }

  const tabClasses = {
    root: classes.tab,
    selected: classes.tabSelected,
  }
  return (
    <div className={classes.container}>
      <Tabs
        value={pathname}
        // @ts-expect-error FIXME: onChange type
        onChange={handleChange}
        classes={{
          root: classes.tabs,
          indicator: classes.indicator,
        }}
      >
        <Tab
          label={t('create')}
          value={getFormPathForTab('')}
          classes={tabClasses}
        />
        <Tab
          label={t('preview')}
          value={getFormPathForTab('preview')}
          classes={tabClasses}
        />
        <Tab
          label={t('settings')}
          value={getFormPathForTab('settings')}
          classes={tabClasses}
        />
      </Tabs>
    </div>
  )
}

FormBuilderNavigation.displayName = 'FormBuilderNavigation'
