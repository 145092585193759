import * as React from 'react'

function SvgChevronUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.586 9a2 2 0 012.701-.117l.127.117 4.293 4.293a1 1 0 01-1.32 1.497l-.094-.083L12 10.414l-4.293 4.293a1 1 0 01-1.497-1.32l.083-.094L10.586 9z'
      />
    </svg>
  )
}

export default SvgChevronUp
