import * as React from 'react'

function SvgConditions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 67 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        cx={19.145}
        cy={19.84}
        r={17.5}
        stroke='#3476E0'
        strokeWidth={3}
      />
      <circle
        cx={47.145}
        cy={19.84}
        r={17.5}
        stroke='#DFE1E6'
        strokeWidth={3}
      />
      <path
        d='M5.656 14.772a.2.2 0 000 .284l.525.523a.2.2 0 00.282 0l9.208-9.171a.2.2 0 000-.284l-.525-.523a.2.2 0 00-.283 0l-9.207 9.171zm-.65 4.149a.2.2 0 000 .283l.525.523a.2.2 0 00.282 0L19.41 6.184a.2.2 0 000-.284l-.525-.523a.2.2 0 00-.283 0L5.005 18.92zm20.696-.238a.2.2 0 000-.283l-.525-.523a.2.2 0 00-.282 0L11.298 31.421a.2.2 0 000 .283l.525.524a.2.2 0 00.282 0l13.597-13.545zm0 4.064a.2.2 0 000-.283l-.524-.523a.2.2 0 00-.282 0L14.259 32.536a.2.2 0 000 .283l.526.523a.2.2 0 00.282 0l10.635-10.595zM17.1 33.418a.2.2 0 000 .284l.526.523a.2.2 0 00.282 0l8.632-8.598a.2.2 0 000-.284l-.525-.523a.2.2 0 00-.283 0l-8.631 8.598zm3.468.31a.2.2 0 000 .284l.525.523a.2.2 0 00.283 0l6.476-6.451a.2.2 0 000-.284l-.526-.523a.2.2 0 00-.282 0l-6.476 6.451zM22.49 6.604a.2.2 0 000-.283l-.525-.524a.2.2 0 00-.283 0L5.062 22.352a.2.2 0 000 .283l.525.524a.2.2 0 00.282 0L22.49 6.604zm2.776.663a.2.2 0 000-.283l-.525-.523a.2.2 0 00-.282 0L6.027 24.82a.2.2 0 000 .283l.526.524a.2.2 0 00.282 0l18.43-18.36zm2.772 1.096a.2.2 0 000-.284l-.526-.523a.2.2 0 00-.282 0L7.22 27.486a.2.2 0 000 .283l.525.524a.2.2 0 00.283 0l20.01-19.93zm-1.968 5.905a.2.2 0 000-.284l-.526-.524a.2.2 0 00-.282 0L8.969 29.688a.2.2 0 000 .284l.525.523a.2.2 0 00.283 0L26.07 14.267z'
        fill='#3476E0'
        stroke='#3476E0'
        strokeWidth={0.4}
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgConditions
