import * as React from 'react'

function SvgDescriptionQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 4H6c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6c0-1.1-.9-2-2-2zM7.04 6.81h9.9c.55 0 1 .45 1 1s-.45 1-1 1h-9.9c-.55 0-1-.45-1-1s.44-1 1-1zm0 4.19h7c.55 0 1 .45 1 1s-.45 1-1 1h-7c-.55 0-1-.45-1-1s.44-1 1-1z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDescriptionQuestion
