import * as React from 'react'

function SvgIcd10Classification(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='-4 -4 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11.362 9.982h-.582V6.026h.594c.23 0 .432.04.609.12.176.078.325.194.447.349.121.155.212.346.273.573.063.227.095.49.095.79v.284c0 .39-.056.723-.166.998a1.312 1.312 0 01-.483.63c-.214.141-.476.212-.787.212z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm5.632 8.984h1.183c-.024.393-.13.741-.32 1.046-.188.305-.45.543-.788.713-.335.171-.738.257-1.21.257-.37 0-.7-.064-.993-.192a2.115 2.115 0 01-.751-.562 2.51 2.51 0 01-.47-.881 3.862 3.862 0 01-.163-1.159v-.404c0-.428.055-.814.166-1.159.113-.347.274-.642.483-.885.21-.244.462-.43.755-.562.293-.13.62-.196.98-.196.48 0 .886.088 1.215.265.332.176.59.419.771.729.185.31.296.663.333 1.058H7.636a1.564 1.564 0 00-.138-.597.791.791 0 00-.36-.373c-.158-.085-.37-.128-.633-.128-.198 0-.37.037-.518.112a.966.966 0 00-.372.34c-.1.153-.175.346-.225.578-.048.23-.072.5-.072.81v.412c0 .302.023.568.068.798.044.227.113.42.205.577a.963.963 0 00.364.353c.15.077.331.116.542.116.248 0 .452-.04.613-.12.16-.08.284-.2.368-.357.087-.158.138-.354.154-.589zm3.73 1.936H9.594V5.084h1.78c.385 0 .737.067 1.056.2.322.132.6.32.834.566.235.246.416.54.542.881.13.34.194.718.194 1.135v.276c0 .414-.065.793-.194 1.135a2.532 2.532 0 01-.542.881c-.232.244-.51.432-.834.566-.322.13-.678.196-1.068.196zm-8.18 0V5.084H2v5.836h1.183z'
      />
    </svg>
  )
}

export default SvgIcd10Classification
