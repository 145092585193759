import * as React from 'react'

function SvgMultipleChoiceQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 4h12c1.1 0 2 .9 2 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6c0-1.1.9-2 2-2zm5.84 11.03l4.31-4.9c.32-.37.3-.92-.07-1.24a.886.886 0 00-1.25.08l-3.69 4.19-1.99-1.99a.875.875 0 00-1.25 0c-.35.34-.35.9 0 1.25l2.65 2.66c.17.15.38.25.62.25h.03c.25-.01.47-.11.64-.3zM6 6h12v12H6V6zM5 6a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H6a1 1 0 01-1-1V6z'
      />
    </svg>
  )
}

export default SvgMultipleChoiceQuestion
