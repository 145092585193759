import { useTranslation } from 'react-i18next'
import { createApolloCacheUpdate, updateQuery } from '@awell/libs-web/graphql'
import { useNotifications } from '../useNotifications'
import {
  type CreatePathwayCaseMutation,
  GetPathwayCasesDocument,
  type GetPathwayCasesQuery,
  type GetPathwayCasesQueryVariables,
  type PathwayCaseSummary,
  useCreatePathwayCaseMutation,
} from './types'

interface UseCreatePathwayCaseHook {
  createPathwayCase: (title: string) => Promise<string | void>
}

export const useCreatePathwayCase = ({
  pathwayId,
}: {
  pathwayId: string
}): UseCreatePathwayCaseHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()

  const variables: GetPathwayCasesQueryVariables = {
    input: { pathway_id: pathwayId },
  }

  const [createPathwayCaseMutation] = useCreatePathwayCaseMutation()
  const createPathwayCase = async (title: string) => {
    try {
      const update = createApolloCacheUpdate<
        CreatePathwayCaseMutation,
        GetPathwayCasesQuery,
        GetPathwayCasesQueryVariables
      >({
        query: GetPathwayCasesDocument,
        variables,
        updateFunction: ({ query, result }) => {
          const pathwayCase = result.data?.createPathwayCase.pathway_case
          if (pathwayCase) {
            const pathway_cases = [
              ...query.pathway_cases.pathway_cases,
              pathwayCase,
            ]
            return updateQuery<GetPathwayCasesQuery, Array<PathwayCaseSummary>>(
              query,
              ['pathway_cases', 'pathway_cases'],
              pathway_cases,
            )
          }
          return query
        },
      })
      const { data } = await createPathwayCaseMutation({
        variables: {
          input: {
            pathway_id: pathwayId,
            title,
          },
        },
        update,
      })
      return data?.createPathwayCase.pathway_case.id
    } catch (error) {
      notifyError({
        message: t('pathway_case_create_error'),
        error,
      })
      return undefined
    }
  }

  return { createPathwayCase }
}
