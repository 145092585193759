// @ts-nocheck TODO: remove this comment and fix errors
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InlineText } from '../Typography/TypographyNew'
import { Checkmark, ChevronDown, ChevronUp } from '../Icons'
import { TypeVariants, TypographyProps } from '../Typography'
import { colors, spacing } from '../../utils/style-guide'
import { IconWrapper } from '../IconWrapper'
import { Popover } from '../Popover'
import { useStyles } from './useStyles'

export interface PopoverSelectorItemType {
  id: string
  title: string
}

type SelectorVariantType = 'header' | 'default'

interface PopoverSelectorProps {
  items: Array<PopoverSelectorItemType>
  onItemSelect: (item: PopoverSelectorItemType) => void
  activeItem: PopoverSelectorItemType
  Icon: FC
  variant?: SelectorVariantType
  dataHeap?: string
}

type TitlePropsType = Pick<
  TypographyProps,
  'span' | 'fitContent' | 'variant' | 'color' | 'style'
>

export const PopoverSelector: FC<PopoverSelectorProps> = ({
  items = [],
  onItemSelect,
  activeItem,
  Icon,
  variant = 'default',
  dataHeap,
}) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const titleProps = (selectorVariant: SelectorVariantType): TitlePropsType => {
    switch (selectorVariant) {
      case 'header':
        return {
          span: true,
          fitContent: true,
          variant: TypeVariants.medium,
        }
      default:
        return {
          span: true,
          fitContent: true,
          variant: TypeVariants.h5,
          color: colors.neutralDark500,
          style: { marginLeft: spacing.xxs },
        }
    }
  }

  const handleKeyPress = (event, item) => {
    if (event.key === 'Enter') {
      onItemSelect(item)
      event.preventDefault()
    }
  }

  return (
    <Popover placement='bottom-start' setPopoverOpen={setPopoverOpen}>
      <Popover.Target>
        <div className={classes.selector} data-heap={dataHeap}>
          <InlineText variant='smallHeadline' color={colors.neutralLight100}>
            {activeItem ? activeItem.title : t('not_selected')}
          </InlineText>
          <IconWrapper
            className={classes.selectorIcon}
            size='xs'
            color={colors.neutralLight100}
          >
            {popoverOpen ? <ChevronUp /> : <ChevronDown />}
          </IconWrapper>
        </div>
      </Popover.Target>
      <Popover.Content>
        <div className={classes.listContainer}>
          {items.map(item => {
            return (
              <div
                role='option'
                aria-selected
                tabIndex={0}
                onKeyPress={e => handleKeyPress(e, item)}
                className={classes.listItem}
                key={item.id}
                onClick={() => onItemSelect(item)}
              >
                <div className={classes.titleWithIcon}>
                  <IconWrapper
                    inline
                    size='xs'
                    color={colors.neutralDark500}
                    className={classes.listItemIcon}
                  >
                    <Icon />
                  </IconWrapper>
                  <InlineText color={colors.neutralDark500}>
                    {item.title}
                  </InlineText>
                </div>
                {activeItem?.id === item.id && (
                  <IconWrapper>
                    <Checkmark />
                  </IconWrapper>
                )}
              </div>
            )
          })}
        </div>
      </Popover.Content>
    </Popover>
  )
}

PopoverSelector.displayName = 'PopoverSelector'
