import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  type ReactNode,
} from 'react'

export enum DrawerType {
  Versions = 'versions',
  Step = 'step',
}

type DrawerContextType = {
  state: { [key in DrawerType]: boolean }
  onClose: (type: DrawerType) => void
  onOpen: (type: DrawerType) => void
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined)

type DrawerProviderProps = {
  children: ReactNode
}

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [state, setState] = useState<{ [key in DrawerType]: boolean }>({
    [DrawerType.Versions]: false,
    [DrawerType.Step]: false,
  })

  const onClose = useCallback((type: DrawerType) => {
    setState(prev => ({
      ...prev,
      [type]: false,
    }))
  }, [])

  const onOpen = useCallback((type: DrawerType) => {
    setState(prev => ({
      ...prev,
      [type]: true,
    }))
  }, [])

  return (
    <DrawerContext.Provider value={{ state, onClose, onOpen }}>
      {children}
    </DrawerContext.Provider>
  )
}

export const useDrawer = (type: DrawerType) => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }

  return {
    isOpen: context.state[type],
    onClose: () => {
      context.onClose(type)
    },
    onOpen: () => {
      context.onOpen(type)
    },
  }
}
