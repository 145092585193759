import * as React from 'react'

function SvgLibrary(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 2a1 1 0 011-1h14a1 1 0 011 1v16a1 1 0 11-2 0V3H6a1 1 0 01-1-1zM3.466 5h13.068c.26 0 .466.218.466.484v17.032a.495.495 0 01-.056.228.455.455 0 01-.63.198L10 19.42l-6.314 3.522a.426.426 0 01-.22.058.477.477 0 01-.466-.484V5.484C3 5.218 3.21 5 3.466 5z'
      />
    </svg>
  )
}

export default SvgLibrary
