import {
  DataCatalogDocument,
  DataCatalogJsonDocument,
  PathwayDataPointDocument,
} from '../../generated/types-design'
import type { QueryOptions } from '@apollo/client'
import { usePathwayContext } from '../usePathwayContext'

export const useRefetchQueriesForDataPoints = (): Array<QueryOptions> => {
  const { pathwayId } = usePathwayContext()
  const refetchPathwayDataPointQuery = {
    query: PathwayDataPointDocument,
    variables: {
      input: {
        pathway_definition_id: pathwayId,
      },
    },
  }

  const refetchDataCatalogQuery = {
    query: DataCatalogDocument,
    variables: {
      input: {
        pathway_definition_id: pathwayId,
      },
    },
  }
  const refetchDataCatalogJsonQuery = {
    query: DataCatalogJsonDocument,
    variables: {
      input: {
        pathway_definition_id: pathwayId,
      },
    },
  }
  return [
    refetchPathwayDataPointQuery,
    refetchDataCatalogQuery,
    refetchDataCatalogJsonQuery,
  ]
}
