import { type RouteComponentProps } from '@reach/router'
import { isEmpty } from 'lodash'
import React, { type FC, lazy, useEffect } from 'react'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { usePathwayNavigationInfo } from '../../hooks/usePathwayNavigationInfo'
import { type PathwayRouteProps } from '../types'
import { useTranslation } from 'react-i18next'

const PathwayPage = lazy(
  async () =>
    await import(/* webpackPrefetch: true */ '../../pages/Pathway').then(
      module => ({
        default: module.PathwayPageContainer,
      }),
    ),
)

export const Pathway: FC<RouteComponentProps<PathwayRouteProps>> = ({
  pathwayId,
}) => {
  const { t } = useTranslation()
  const { pathwayName } = usePathwayNavigationInfo(pathwayId)
  const { addBreadcrumbs, updateBreadcrumb } = useBreadcrumbs()

  const breadcrumbs = [
    {
      label: t('care_flow_plural'),
      url: '/pathways',
      level: 0,
    },
    {
      label: pathwayName ?? '',
      url: `/pathways/${pathwayId!}`,
      level: 1,
    },
  ]

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumbs)
  }, [])

  useEffect(() => {
    if (!isEmpty(pathwayName)) {
      updateBreadcrumb(breadcrumbs[1])
    }
  }, [pathwayName])

  return <PathwayPage pathwayId={pathwayId!} />
}
