import React, { createContext, FC, useContext, useState } from 'react'

interface BackNavigationContextInterface {
  disabledOnSave: boolean
  setBackButtonDisabledOnSave: (disabledOnSave: boolean) => void
}
const initialContext: BackNavigationContextInterface = {
  disabledOnSave: false,
  setBackButtonDisabledOnSave: () => null,
}

const BackNavigationContext = createContext<BackNavigationContextInterface>(
  initialContext,
)

/**
 *
 * BackNavigationContextProvider handles navigation button state
 *
 */
export const BackNavigationContextProvider: FC = ({ children }) => {
  const [disabledOnSave, setBackButtonDisabledOnSave] = useState(false)

  return (
    <BackNavigationContext.Provider
      value={{
        disabledOnSave,
        setBackButtonDisabledOnSave,
      }}
    >
      {children}
    </BackNavigationContext.Provider>
  )
}

export const useBackNavigationContextProvider = (): BackNavigationContextInterface =>
  useContext(BackNavigationContext)
