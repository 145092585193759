import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { NavigationButton } from '../NavigationButton'
import { InlineText } from '../Typography/TypographyNew'
import { IconWrapper } from '../IconWrapper'
import SvgChevronRight from '../Icons/ChevronRight'
import { PopoverSelector, PopoverSelectorItemType } from '../PopoverSelector'
import { colors, spacing } from '../../utils/style-guide'
import { NavigationWithDropdownSkeleton } from './skeletons/NavigationWithDropdownSkeleton'

export interface NavigationWithDropdownProps {
  navigationLink: string
  parentPageName: string
  onSelect: (item: PopoverSelectorItemType) => void
  dropdownItems: Array<PopoverSelectorItemType>
  activeItem: PopoverSelectorItemType
  icon: FC
  loading?: boolean
}
const useStyles = makeStyles({
  breadCrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing.xs,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})
export const NavigationWithDropdown: FC<NavigationWithDropdownProps> = ({
  navigationLink,
  parentPageName,
  onSelect,
  dropdownItems,
  activeItem,
  icon,
  loading = false,
}) => {
  const classes = useStyles()

  if (loading) {
    return <NavigationWithDropdownSkeleton />
  }

  return (
    <>
      <NavigationButton linkUrl={navigationLink} />
      <div className={classes.breadCrumbContainer}>
        <div className={classes.textContainer}>
          <InlineText variant='smallHeadline' color={colors.neutralDark500}>
            {parentPageName}
          </InlineText>
          <IconWrapper color={colors.neutralDark500}>
            <SvgChevronRight />
          </IconWrapper>
        </div>
        <PopoverSelector
          items={dropdownItems}
          activeItem={activeItem}
          onItemSelect={onSelect}
          Icon={icon}
          variant='header'
        />
      </div>
    </>
  )
}

NavigationWithDropdown.displayName = 'NavigationWithDropdown'
