import * as React from 'react'

function SvgVariableArrowDownShorter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.293 25.62a1 1 0 001.414 0l6.364-6.364a1 1 0 00-1.414-1.415L8 23.498l-5.657-5.657A1 1 0 00.93 19.256l6.364 6.364zM7 .913v24h2v-24H7z'
        fill='#DFE1E6'
      />
    </svg>
  )
}

export default SvgVariableArrowDownShorter
