import * as React from 'react'

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8.464 18.364l-3.535.707.707-3.535 2.828 2.828zM19.778 5.636a1 1 0 010 1.414l-9.9 9.9-2.828-2.83 9.9-9.9a1 1 0 011.414 0l1.414 1.415z'
      />
    </svg>
  )
}

export default SvgEdit
