import * as React from 'react'

function SvgServer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 7h.01M15 7h.01M18 17h.01M15 17h.01M6 10h12c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C21 8.398 21 7.932 21 7c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C19.398 4 18.932 4 18 4H6c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C3 5.602 3 6.068 3 7c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C4.602 10 5.068 10 6 10zm0 10h12c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C21 18.398 21 17.932 21 17c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C19.398 14 18.932 14 18 14H6c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C3 15.602 3 16.068 3 17c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C4.602 20 5.068 20 6 20z'
        stroke='#42526E'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgServer
