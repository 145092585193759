import * as React from 'react'

function SvgVariable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.32 19.29c-1.838-.517-2.776-1.691-2.812-3.524v-1.664c0-1.172-.503-1.758-1.508-1.758v-1.39c1.005 0 1.508-.59 1.508-1.767V7.532c.015-.937.258-1.695.726-2.273C5.708 4.68 6.404 4.26 7.32 4l.375 1.094c-.9.291-1.367 1.088-1.398 2.39v1.688c0 1.172-.45 1.995-1.352 2.469.901.479 1.352 1.31 1.352 2.492v1.664c.031 1.302.497 2.099 1.398 2.39L7.32 19.29zM10.094 14.477c.354 0 .627.101.82.304.193.198.29.448.29.75 0 .297-.097.545-.29.742-.193.193-.466.29-.82.29-.339 0-.607-.097-.805-.29a.99.99 0 01-.297-.742c0-.302.097-.552.29-.75.192-.203.463-.304.812-.304zM14.563 14.477c.354 0 .627.101.82.304.193.198.289.448.289.75 0 .297-.097.545-.29.742-.192.193-.465.29-.82.29-.338 0-.606-.097-.804-.29a.99.99 0 01-.297-.742c0-.302.096-.552.289-.75.193-.203.463-.304.813-.304zM17.086 18.188c.917-.297 1.385-1.11 1.406-2.438v-1.625c0-1.203.474-2.029 1.422-2.477-.948-.447-1.422-1.28-1.422-2.5V7.531c-.02-1.328-.49-2.14-1.406-2.437L17.46 4c.948.266 1.654.698 2.117 1.297.464.594.695 1.372.695 2.336v1.562c0 1.172.506 1.758 1.516 1.758v1.39c-1.01 0-1.516.587-1.516 1.759v1.539c0 .974-.234 1.757-.703 2.351-.463.6-1.166 1.031-2.11 1.297l-.374-1.102z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgVariable
