import * as React from 'react'

function SvgCheckbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <rect
        width={12}
        height={12}
        x={6}
        y={6}
        rx={1}
        stroke='currentColor'
        strokeWidth={2}
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgCheckbox
