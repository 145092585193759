import * as React from 'react'

function SvgLaunch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M10 2v2H4v16h16v-6h2v7a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h7zm12 0v8h-2V5.413l-7.293 7.294-1.414-1.414 7.293-7.294L14 4V2h8z'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgLaunch
