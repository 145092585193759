import * as React from 'react'

function SvgArrowClock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 106 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path stroke='#42526E' strokeLinecap='round' d='M52.5 19.25V22' />
      <path
        d='M74 30c0 12.15-9.85 22-22 22s-22-9.85-22-22S39.85 8 52 8s22 9.85 22 22z'
        stroke='#DFE1E6'
        strokeWidth={4}
      />
      <path
        d='M105.061 31.06a1.501 1.501 0 000-2.12l-9.546-9.547a1.5 1.5 0 10-2.122 2.122L101.88 30l-8.486 8.485a1.5 1.5 0 002.122 2.121l9.546-9.546zM76 31.5h28v-3H76v3z'
        fill='#DFE1E6'
      />
      <path d='M0 30h28' stroke='#DFE1E6' strokeWidth={3} />
      <rect x={50} y={14} width={3} height={20} rx={1.5} fill='#3476E0' />
      <path
        d='M61 30a2 2 0 110 4H51a1 1 0 01-1-1 3 3 0 013-3h8z'
        fill='#3476E0'
      />
    </svg>
  )
}

export default SvgArrowClock
