/* eslint-disable react/display-name */
import { IconButton, Button } from '@awell/ui-kit/components/Button'
import {
  Cross,
  ExclamationMark,
  Informative,
} from '@awell/ui-kit/components/Icons'
import {
  NotificationMessage,
  NotificationMessageContent,
  NotificationMessageContentActions,
  NotificationTypes,
} from '@awell/ui-kit/components/NotificationMessage'
import { useWindowSize } from '@awell/ui-kit/hooks/useWindowSize'
import {
  type SnackbarOrigin,
  useSnackbar,
  SnackbarContent,
  type SnackbarKey,
} from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CrashReporter } from '../../crash-reporter'
import { usePathwayContext } from '../usePathwayContext'
import { useUser } from '../useUser'
import { isNil } from 'lodash'
import { Icon, Toast } from '@awell-health/design-system'

interface Notifications {
  notify: ({
    message,
    description,
    placement,
    notifyType,
  }: {
    message: string
    description?: string
    notifyType?:
      | NotificationTypes.informative
      | NotificationTypes.success
      | NotificationTypes.warning
      | 'delete'
    placement?: {
      vertical: 'top' | 'bottom' | 'middle'
      horizontal: 'start' | 'center' | 'end'
    }
  }) => void
  notifyLong: ({
    message,
    icon,
    placement,
    notifyType,
    persist,
    content,
    actions,
  }: {
    message: string
    notifyType?: NotificationTypes
    icon?: JSX.Element
    placement?: {
      vertical: 'top' | 'bottom'
      horizontal: 'left' | 'center' | 'right'
    }
    persist?: boolean
    content?: React.ReactElement
    actions?: React.ReactElement
  }) => void
  notifyError: ({
    message,
    error,
  }: {
    message: string
    error?: unknown
  }) => void
}

export const useNotifications: () => Notifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { user } = useUser()
  const { pathwayId, pathwayCaseId } = usePathwayContext()
  const { t } = useTranslation()

  const { isMobile = false } = useWindowSize()

  const action = (key: SnackbarKey): JSX.Element => (
    <IconButton
      onClick={() => {
        closeSnackbar(key)
      }}
    >
      <Cross />
    </IconButton>
  )
  const DEFAULT_SNACKBAR_PLACEMENT: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  }
  const MOBILE_SNACKBAR_PLACEMENT: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }

  const DEFAULT_TIME_IN_MS_BEFORE_HIDING = 3000
  const SNACKBAR_MAX_WIDTH_PX = 400 // Defined in Design System

  const handleReport = (key: SnackbarKey, error: Error) => {
    CrashReporter.report(error, {
      user: {
        id: user?.id,
        email: user?.email,
      },
      team: {
        id: user?.team?.id,
        name: user?.team?.name,
      },
      tenant: user?.tenant,
      pathwayId,
      pathwayCaseId,
    })
    closeSnackbar(key)
    enqueueSnackbar(t('error_reported'), {
      anchorOrigin: isMobile
        ? MOBILE_SNACKBAR_PLACEMENT
        : DEFAULT_SNACKBAR_PLACEMENT,
      autoHideDuration: DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      preventDuplicate: true,
      action,
      content: () => (
        <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
          <NotificationMessage
            icon={<Informative />}
            message={t('error_reported')}
            notifyType={NotificationTypes.informative}
            persist={false}
          />
        </SnackbarContent>
      ),
    })
  }

  /**
   * Use this to notify the user about an error using a snackbar notification.
   * The error parameter is optional to make it easier to use this to report
   * errors originating from graphql queries: query hooks always return an error
   * property, so this saves from writing an `if(error !== undefined)` clause
   * for each query.
   */
  const notifyError = ({
    message,
    error,
  }: {
    message: string
    error?: unknown
  }): void => {
    if (error !== undefined && error instanceof Error) {
      // for template pathway error
      if (
        error.message ===
        'User does not have write access on this template pathway'
      ) {
        enqueueSnackbar(t('template_pathway_error'), {
          persist: true,
          action,
          anchorOrigin: isMobile
            ? MOBILE_SNACKBAR_PLACEMENT
            : DEFAULT_SNACKBAR_PLACEMENT,
          content: key => (
            <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
              <NotificationMessage
                icon={<ExclamationMark />}
                message={t('template_pathway_error')}
                onClose={() => {
                  closeSnackbar(key)
                }}
                notifyType={NotificationTypes.informative}
                persist
              >
                <NotificationMessageContent>
                  {t('template_pathway_info_banner')}
                </NotificationMessageContent>
              </NotificationMessage>
            </SnackbarContent>
          ),
        })
        return
      }

      enqueueSnackbar(message, {
        persist: true,
        action,
        anchorOrigin: isMobile
          ? MOBILE_SNACKBAR_PLACEMENT
          : DEFAULT_SNACKBAR_PLACEMENT,
        content: key => (
          <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
            <NotificationMessage
              icon={<ExclamationMark />}
              message={message}
              onClose={() => {
                closeSnackbar(key)
              }}
              notifyType={NotificationTypes.error}
              action={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Button
                  variant='text'
                  onClick={() => {
                    handleReport(key, error)
                  }}
                  data-heap='report-error'
                >
                  {t('report_error')}
                </Button>
              }
              persist
            >
              <NotificationMessageContent>
                {t('error_description', { message: error.message })}
              </NotificationMessageContent>
            </NotificationMessage>
          </SnackbarContent>
        ),
      })
      try {
        // @ts-expect-error Heap SDK is not typed
        window.heap.track('StudioError', {
          tenant: user?.tenant,
          careflow_id: pathwayId,
          error_name: error.name,
          error_message: error.message,
        })
      } catch (err) {
        // Nothing to do here
      }
    }
  }

  /**
   * Legacy notification used in two places only:
   * 1. in useRefreshExtension if notSupportedActions are empty
   * 2. in useMaintenanceStatus if new version is available
   */
  const notifyLong = ({
    message,
    icon = <Informative />,
    placement = isMobile
      ? MOBILE_SNACKBAR_PLACEMENT
      : DEFAULT_SNACKBAR_PLACEMENT,
    notifyType = NotificationTypes.informative,
    persist = false,
    content = null,
    actions = null,
  }: {
    message: string
    notifyType?: NotificationTypes
    icon?: JSX.Element
    placement?: {
      vertical: 'top' | 'bottom'
      horizontal: 'left' | 'center' | 'right'
    }
    persist?: boolean
    content?: React.ReactElement | null
    actions?: React.ReactElement | null
  }): void => {
    enqueueSnackbar(message, {
      anchorOrigin: placement,
      autoHideDuration: DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      preventDuplicate: true,
      persist,
      action,
      content: key => (
        <SnackbarContent style={{ maxWidth: SNACKBAR_MAX_WIDTH_PX }}>
          <NotificationMessage
            icon={icon}
            message={message}
            onClose={() => {
              closeSnackbar(key)
            }}
            notifyType={notifyType}
            persist={persist}
          >
            {(!isNil(content) || !isNil(actions)) && (
              <>
                <NotificationMessageContent>
                  {content}
                </NotificationMessageContent>
                <NotificationMessageContentActions>
                  {actions}
                </NotificationMessageContentActions>
              </>
            )}
          </NotificationMessage>
        </SnackbarContent>
      ),
    })
  }

  /**
   * Use notify for delete, informative, warning and success notifications
   * You can also use it for errors if no user action (e.g. error reporting)
   * Defaults to success notification type and icon
   */
  const notify = ({
    message,
    description,
    notifyType = NotificationTypes.success,
  }: {
    message: string
    description?: string
    notifyType?:
      | NotificationTypes.informative
      | NotificationTypes.success
      | NotificationTypes.warning
      | 'delete'
  }): void => {
    const renderIcon = (): JSX.Element => {
      switch (notifyType) {
        case 'delete':
          return <Icon className='fill-red-600' icon='RiDeleteBinFill' />
        case NotificationTypes.warning:
          return <Icon className='fill-orange-600' icon='RiErrorWarningFill' />
        case NotificationTypes.informative:
          return <Icon className='fill-slate-400' icon='RiInformation2Fill' />
        default:
          return <Icon className='fill-green-600' icon='RiCheckboxFill' />
      }
    }
    enqueueSnackbar(message, {
      anchorOrigin: isMobile
        ? MOBILE_SNACKBAR_PLACEMENT
        : DEFAULT_SNACKBAR_PLACEMENT,
      autoHideDuration: DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      preventDuplicate: true,
      persist: false,
      action,
      content: key => (
        <SnackbarContent>
          <Toast
            handleClose={() => {
              closeSnackbar(key)
            }}
            title={message}
            text={description}
            icon={renderIcon()}
          ></Toast>
        </SnackbarContent>
      ),
    })
  }

  return { notifyError, notify, notifyLong }
}
