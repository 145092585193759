import React, { type FC, useEffect } from 'react'
import { Redirect, type RouteComponentProps } from '@reach/router'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'

export const Home: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()

  const breadcrumb = {
    label: 'Home',
    url: '/',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])
  return <Redirect to='/pathways' noThrow />
}

Home.displayName = 'Home'
