export const isValidUrl = (input: string, enforceHttps = true): boolean => {
  const url = input?.toLowerCase() ?? ''

  if (enforceHttps && !url.startsWith('https://')) {
    return false
  }
  if (
    !enforceHttps &&
    !url.startsWith('http://') &&
    !url.startsWith('https://')
  ) {
    return false
  }
  try {
    // eslint-disable-next-line no-new
    new URL(input)
    return true
  } catch {
    return false
  }
}
