import * as React from 'react'

function SvgTrackActivation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 57 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.704 10.48V6.934H.145v3.545h3.56zM10.821 10.48V6.934H7.263v3.545h3.558z'
        fill='#DFE1E6'
      />
      <path
        d='M3.704 21.112v-3.544H.145v3.544h3.56zM10.821 21.112v-3.544H7.263v3.544h3.558zM56.145 30.063l-8.395-8.277-2.503 2.52 3.952 3.896H36.357L25.681 17.568h-11.3v3.545h9.827l10.676 10.633h14.495l-4.132 4.075 2.504 2.52 8.395-8.279z'
        fill='#3476E0'
      />
      <path
        d='M47.75.34l-2.503 2.52 4.132 4.075H14.38v3.544H49.2l-3.952 3.896 2.504 2.52 8.395-8.277L47.75.34z'
        fill='#DFE1E6'
      />
    </svg>
  )
}

export default SvgTrackActivation
