import * as React from 'react'

function SvgDataPoint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.005 6.5a2.5 2.5 0 112.616 4.235 2.5 2.5 0 01-3.123-3.729L4.995 5.5a2.5 2.5 0 01-3.729-3.123A2.5 2.5 0 016 3.505a2.448 2.448 0 01-.5 1.488L7.005 6.5zM3.5 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgDataPoint
