import { makeStyles } from '@material-ui/core'
import { spacing, colors } from '../../utils/style-guide'

export const useStyles = makeStyles({
  selector: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  selectorIcon: {
    marginLeft: spacing.xxxs,
  },
  listContainer: {
    padding: spacing.xxxs,
    minWidth: spacing.input.l,
    maxHeight: spacing.list.m,
    overflowY: 'auto',
  },
  listItem: {
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    borderRadius: spacing.xxxs,
    '&:hover': {
      backgroundColor: colors.brand10,
    },
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWithIcon: {
    display: 'flex',
  },
  listItemIcon: {
    marginRight: spacing.xxs,
  },
})
