import * as React from 'react'

function SvgVariableTransformation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 88 33'
      fill='none'
      {...props}
    >
      <mask id='variable-transformation_svg__a' fill='#fff'>
        <rect y={6.913} width={20} height={20} rx={2.561} />
      </mask>
      <rect
        y={6.913}
        width={20}
        height={20}
        rx={2.561}
        stroke='#3476E0'
        strokeWidth={8}
        mask='url(#variable-transformation_svg__a)'
      />
      <path
        d='M27.541 15.413h17.197l-5.075-4.94a1.472 1.472 0 010-2.12 1.572 1.572 0 012.18 0l7.706 7.5a1.472 1.472 0 010 2.12l-7.707 7.5c-.3.294-.695.44-1.09.44-.394 0-.788-.146-1.09-.44a1.472 1.472 0 010-2.12l5.076-4.94H27.54c-.85 0-1.541-.672-1.541-1.5s.69-1.5 1.541-1.5z'
        fill='#C1C7D0'
      />
      <circle cx={72} cy={16.913} r={14} stroke='#3476E0' strokeWidth={4} />
    </svg>
  )
}

export default SvgVariableTransformation
