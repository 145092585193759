import { makeVar } from '@apollo/client'
import { type CalculationLibraryType } from '../generated/types-design'

export const layoutVar = makeVar({
  startPanelWidth: 300,
  endPanelWidth: 0,
  startPanelCollapsed: true,
})
type TrackCanvasSettings = Record<string, {
  x: number
  y: number
  zoom: number
}>;
export const trackCanvasSettingsVar = makeVar<TrackCanvasSettings>({})
export const builderUrlVar = makeVar('')
export const previewUrlVar = makeVar('')
export const currentPageTitleVar = makeVar('')
export const previewLayoutVar = makeVar({
  activityFeedWidth: 0.25,
})
export const calculationsLibraryVar = makeVar<Array<CalculationLibraryType>>([])
