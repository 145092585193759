import * as React from 'react'

function SvgRestart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      fill='none'
      {...props}
    >
      <path
        d='M23.533 8.467a10.6 10.6 0 00-8.64-3.08c-4.893.493-8.92 4.466-9.466 9.36-.734 6.466 4.266 11.92 10.573 11.92a10.64 10.64 0 009.613-6.08 1.336 1.336 0 00-1.2-1.92c-.493 0-.96.266-1.173.706a7.992 7.992 0 01-9.067 4.414c-2.96-.654-5.346-3.067-5.973-6.027A8.003 8.003 0 0116 8c2.213 0 4.186.92 5.627 2.373l-2.014 2.014c-.84.84-.253 2.28.934 2.28h4.786c.734 0 1.334-.6 1.334-1.334V8.547c0-1.187-1.44-1.787-2.28-.947l-.854.867z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgRestart
