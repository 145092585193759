import { FullHeightRouter, LogoutRoute } from '@awell/libs-web/routing'
import { ErrorPage as NotFound } from '@awell/ui-kit/compositions/ErrorPage'
import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { Home } from './Home'
import { Pathway, Pathways, PathwayCaseRoute } from './Pathway'
import { PublicRoute } from './PublicRoute'
import { Spinner } from '@awell/ui-kit/components/Spinner'

const Track = lazy(
  async () =>
    await import(/* webpackPrefetch: true */ './Track').then(module => ({
      default: module.Track,
    })),
)

const FormBuilder = lazy(
  async () =>
    await import(/* webpackPrefetch: true */ './Pathway/FormBuilder').then(
      module => ({
        default: module.FormBuilder,
      }),
    ),
)

export const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback=''>
        <FullHeightRouter>
          <Home path='/' />
          <LogoutRoute loadingComponent={<Spinner />} path='/logout' />
          <Pathways path='/pathways' />
          <Track path='/pathways/:pathwayId/build/:trackId/*' />
          <FormBuilder path='/pathways/:pathwayId/build/forms/*' />
          <PathwayCaseRoute path='/pathways/:pathwayId/cases/:caseId/*' />
          <Pathway path='/pathways/:pathwayId/*' />
          <PublicRoute path='/share/*' />
          <NotFound sendHome default />
        </FullHeightRouter>
      </Suspense>
    </ErrorBoundary>
  )
}
