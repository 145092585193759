import { environment } from '@awell/libs-web/environment'
import { environmentMapper } from '@awell/libs-web/experiments'
import React, { useEffect, useState, type FC } from 'react'
import { useUser } from '../hooks/useUser'

import {
  StatsigClient,
  type StatsigOptions,
  type StatsigUser,
} from '@statsig/js-client'

import { StatsigProvider } from '@statsig/react-bindings'
import { Spinner } from '@awell/ui-kit/src/components/Spinner'

const statsigOptions: StatsigOptions = {
  environment: {
    tier: environmentMapper(environment.environment),
  },
  logLevel: 4,
}

const myStatsigClient = new StatsigClient(
  environment.statsig.client_key,
  { userID: undefined },
  statsigOptions,
)

myStatsigClient.initializeSync()

export const FeatureGateProvider: FC = ({ children }) => {
  const { user, loading } = useUser()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!loading && user?.id !== null) {
      const statsigUser: StatsigUser = {
        userID: user?.id ?? '',
        email: user?.email ?? '',
        custom: {
          tenant_name: user?.tenant?.name ?? '',
        },
        // custom IDs allow us to A/B test features at the
        // tenant level instead of at the user level.
        // https://docs.statsig.com/guides/experiment-on-custom-id-types
        customIDs: {
          tenant_id: user?.tenant?.id ?? '',
        },
      }

      myStatsigClient.updateUserAsync(statsigUser).then(() => {
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
        throw new Error('Error updating statsig user')
      })
    }
  }, [loading, user])

  if (isLoading) {
    return <Spinner />
  }

  return <StatsigProvider client={myStatsigClient}>{children}</StatsigProvider>
}
